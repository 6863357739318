import AirportFilter from '../airport-filter/main'
import DatesFilter from '../dates-filter/main'
import DestinationFilter from '../destination-filter/main'
import DropdownFilter from '../dropdown-filter/main'
import DropdownMultipleFilter from '../dropdown-multiple-filter/main'
import DropdownDynamicFilter from '../dropdown-dynamic-filter/main'
import ParticipantsFilter from '../participants-filter/main'
import FiltersCollection from '../../../js/data/filters/filters-collection'
import { arrayifyObject } from '../../../js/helpers/arrayify-object'
import { registerWidget } from '../../../js/core/widget/widget-directory'
import { debounce } from '../../../js/utils'
import { toCamelCase } from '../../../js/helpers/string'
import { fetchJsonData } from '../../../js/helpers/json-fetch'
import { getUrlFromString } from '../../../js/document/url'
import { removeUndefinedKeys } from '../../../js/helpers/object'
import { FILTER_TYPES, TRANSPORT_TYPE_VALUES } from '../../../js/data/filters/config'
const EventEmitter = require('eventemitter3')

// Definition of available FilterViews
const knownFilterViews = {
  airport: AirportFilter,
  dates: DatesFilter,
  destination: DestinationFilter,
  dropdown: DropdownFilter,
  'dropdown-multiple': DropdownMultipleFilter,
  participants: ParticipantsFilter,
  'dropdown-dynamic': DropdownDynamicFilter
}

const customFilterDataTransformations = {
  DepartureDate: (values) => [values[0], values[values.length - 1]]
}

const widgetApi = 'w-quick-search'

const widgetQueries = {
  urlAttr: `data-${widgetApi}__url`,
  publicationsButtons: '[data-js-component="c-button-group"]',
  publicationsButtonsApi: 'c-button-group',
  publicationCodeAttr: `data-${widgetApi}__publication-id`,
  publicationFilterAttr: `data-${widgetApi}__publication-filters`,
  filtersElements: `[class*="${widgetApi}__filters"]`,
  submitButton: `[data-${widgetApi}__submit-btn]`,
  submitButtonApi: 'c-btn',
  submitButtonPatternAttr: 'data-w-quick-search__submit-pattern',
  extraParams: 'input[type="hidden"]',
  filterElements: (id) => `[data-w-filters__id="${id}"]`,
  filterViewAttr: 'data-w-filters__view',
  filterNameAttr: 'data-w-filters__name',
  participantsFilterNameAttr: 'data-w-filters__name-participants',
  allocationFilterNameAttr: 'data-w-filters__name-allocation'
}

export default class QuickSearch {
  /**
   * Creates a new QuickSearch
   *
   * @constructor
   *
   * @param {HTMLElement} element - The element where to attach QuickSearch
   * @param {Object} options
   * @param {String} options.url - The url where to fetch data
   */
  constructor (element, options = {}) {
    this.element = element
    this.events = new EventEmitter()
    this.element[widgetApi] = {
      events: this.events
    }
    this.isFirstLoad = true

    this.initFilterElements()

    this.initPublications()

    // Init submit button
    this.submitButton = this.element.querySelector(widgetQueries.submitButton)
    this.submitButtonApi = this.submitButton[widgetQueries.submitButtonApi]

    // Init extra params
    this.extraParams = this.element.querySelectorAll(widgetQueries.extraParams)
    this.extraParams = this.extraParams
      ? [...this.extraParams].reduce((obj, el) => {
          obj[el.name] = el.value
          return obj
        }, {})
      : undefined

    // QueryParams
    this.queryParams = { ...{ publicationCode: this.publicationCode }, ...this.extraParams }

    // Selected Values
    this.selectedValues = { ...this.getParticipantsDataFromComponent() }

    // Init URL and fetch initial data
    this.url = options.url || this.element.getAttribute(widgetQueries.urlAttr)

    this.fetchAndUpdate()

    // Bind self change events to self update
    this.debouncedChangeHandler = debounce(() => {
      this.fetchAndUpdate()
    }, 150)
    this.events.on('change', () => this.debouncedChangeHandler())

    this.destinationSelectedValues = []
  }

  initFilterElements () {
    this.filtersElements = this.element.querySelectorAll(widgetQueries.filtersElements)
    this.filterElements = this.element.querySelectorAll(widgetQueries.filterElements(this.element.id))
  }

  initFiltersCollection (filtersData = []) {
    const filtersCollection = new FiltersCollection(filtersData)

    filtersCollection.events.on('updated', () => {
      this.selectedValues = filtersCollection.getFiltersSelectedValuesObject()

      // Override destination filter values with processed values
      if (this.useDestinationMultiple) {
        const destinationFilter = filtersCollection.models.find(model => model.getAttribute('type') === FILTER_TYPES.DESTINATION)
        const lowestTypeValues = destinationFilter ? destinationFilter.selectedValuesLowestType : null

        if (lowestTypeValues) {
          this.destinationSelectedValues = lowestTypeValues.map(destination => destination.getAttribute('value'))
        }
        this.selectedValues.Destination = this.destinationSelectedValues
      }

      this.events.emit('change')
    })
    return filtersCollection
  }

  async fetchAndUpdate (options = {}) {
    this.setEnabledState(false, true)

    if (!this.filtersCollection) this.filtersCollection = this.initFiltersCollection()

    this.requestUrl = this.buildRequestURL()

    const newData = await fetchJsonData(this.requestUrl, { fullReferrerOnCrossOrigin: true })

    this.isFirstLoad = false

    const part = newData.filters.find(filter => filter.type === FILTER_TYPES.PARTICIPANTS)
    if (part) {
      part.isMultiselectable = true
      part.maxRooms = newData.maxRooms
      part.humanizedTextPattern = newData.participantsHumanizedText
      part.ageProfiles = newData.ageProfiles
    }

    this.filtersCollection.reset(newData.filters, { silent: true })
    this.selectedValues = this.filtersCollection.getFiltersSelectedValuesObject()
    this.initFilters()

    const submitButtonDisable = newData.accommodationCount === 0
    if (this.submitButtonApi) {
      this.updateSubmit(newData)
    }
    this.setEnabledState(true, submitButtonDisable)
    this.events.emit('fetchAndUpdateFinished')
  }

  /**
   * Init publications buttons and bind actions
   * - Find for elements
   * - Get the current active element and it's publication code associated
   * - Bind changes on publicationsButtons to update the publication code
   * - Update filter visibility according every publication configuration
   *
   * @returns {QuickSearch} self instance
   */
  initPublications () {
    this.publicationsButtons = this.element.querySelector(widgetQueries.publicationsButtons) || undefined
    if (!this.publicationsButtons) return this

    this.publicationsButtonsApi = this.publicationsButtons[widgetQueries.publicationsButtonsApi]
    const activeElement = this.publicationsButtonsApi.getProp('activeElement')

    this.publicationCode = activeElement.getAttribute(widgetQueries.publicationCodeAttr)
    this.activeFilters = activeElement.getAttribute(widgetQueries.publicationFilterAttr).split(',')

    this.publicationsButtonsApi.events.on('changeActiveElement', el => {
      this.publicationCode = el.getAttribute(widgetQueries.publicationCodeAttr)
      this.queryParams = {
        ...this.queryParams,
        ...{ publicationCode: this.publicationCode }
      }
      this.activeFilters = el.getAttribute(widgetQueries.publicationFilterAttr).split(',')
      this.filtersCollection.clearSelection()
      this.selectedValues = {}
      this.isFirstLoad = true
      this.events.emit('change')
    })
  }

  /**
   * Init filters
   *
   * @param {FiltersCollection} [filtersCollection] - The filters data
   *
   * @returns {QuickSearch} self instance
   */
  initFilters (filtersCollection = this.filtersCollection) {
    this.filterViews = this.filterViews || []
    this.filterElements.forEach(el => {
      // Get the element filter view, and check if it's known & available
      const filterView = el.getAttribute(widgetQueries.filterViewAttr)
      if (!knownFilterViews[filterView]) return

      // Check the filter name
      const filterName = el.getAttribute(widgetQueries.filterNameAttr) ||
        el.getAttributeNames().filter((attrKey) => attrKey.indexOf(widgetQueries.filterNameAttr) === 0)

      if (this.activeFilters) {
        const active = Array.isArray(filterName)
          ? filterName.some(name => this.activeFilters.includes(el.getAttribute(name)))
          : this.activeFilters.includes(filterName)
        el.classList.toggle('is-active', active)
      }

      // Get the element filter data, and check if it's available
      const filterData = !Array.isArray(filterName)
        ? filtersCollection.findWhere('type', filterName)
        : filterName.map((attrKey) => [
          toCamelCase(attrKey.replace(`${widgetQueries.filterNameAttr}-`, '')),
          el.getAttribute(attrKey)
        ]).reduce((obj, [attrKey, attrValue]) => ({
          ...obj,
          [attrKey]: filtersCollection.findWhere('type', attrValue)
        }), {})

      // Disable filter when no facets are returned
      this._enableFilters(el, filterName, filterData)

      if (!filterData) return

      // Init or update the view with associated data if necessary
      const name = !Array.isArray(filterName) ? filterName : filterName.map((attrKey) => el.getAttribute(attrKey)).join('_')
      const filterViewObject = this.filterViews.find(v => v.name === name)
      filterViewObject
        ? filterViewObject.instance.setFilterModel(filterData)
        : this.filterViews.push({
          name,
          view: filterView,
          instance: new knownFilterViews[filterView](el, filterData),
          data: filterData
        })
    })
    return this
  }

  _enableFilters (element, filterName, filterData) {
    let shouldDisable = !filterData
    if (filterName === FILTER_TYPES.DEPARTURE_AIRPORT && (FILTER_TYPES.TRANSPORT_TYPE in this.selectedValues) && this.selectedValues.TransportType === TRANSPORT_TYPE_VALUES.BUS) {
      shouldDisable = true
    }
    element.classList.toggle('is-disabled', shouldDisable)
  }

  /**
   * Enable or Disable UI
   * - Filters element (wrapper)
   * - Submit Button
   * - Publications buttons, only if:
   *   - Are present (remember it's an optional element)
   *   - Are disabled (should happen only once, on first load)
   *
   * @param {Boolean} enabled - The enable state
   *
   * @returns {QuickSearch} self instance
   */
  setEnabledState (enabled = true, submitButtonDisable = false) {
    // Filters
    this.filtersElements.forEach(el => el.classList.toggle('is-disabled', !enabled))
    // Submit buttons
    if (this.submitButtonApi) {
      this.submitButtonApi.setProps({
        disabled: submitButtonDisable,
        loading: !enabled
      })
    }
    // Option
    if (this.publicationsButtons && this.publicationsButtonsApi.getProp('disabled') && enabled) { this.publicationsButtonsApi.setProp('disabled', !enabled) }
    return this
  }

  /**
   * Updates submit with link and count
   *
   * @param {Object} freshData - The requested data
   *
   * @returns {QuickSearch} self instance
   */
  updateSubmit (freshData) {
    const pattern = this.submitButton.getAttribute(widgetQueries.submitButtonPatternAttr)
    if (pattern && this.submitButtonApi && typeof freshData.accommodationCount === 'number') {
      this.submitButtonApi.setProp('text', pattern.replace('{N}', freshData.accommodationCount.toString()))
    }
    if (freshData.searchLink) { this.submitButton.setAttribute('href', freshData.searchLink) }
    return this
  }

  buildRequestURL () {
    return getUrlFromString(this.url, this.fixParamsBeforeUse({
      ...this.queryParams,
      ...removeUndefinedKeys(this.selectedValues),
      ...{ isFirstLoad: this.isFirstLoad }
    }))
  }

  fixParamsBeforeUse (params = {}) {
    const paramsFixed = arrayifyObject(
      Object.entries(params)
        .reduce((newObj, [key, val]) => {
          newObj[key] = customFilterDataTransformations[key]
            ? customFilterDataTransformations[key](val)
            : val
          return newObj
        }, {})
    )
    return paramsFixed
  }

  getParticipantsDataFromComponent () {
    const participantsSelectorAPI = this._getParticipantsSelectorAPI()

    return participantsSelectorAPI
      ? {
          Participants: this.participantsSelectorAPI.getParticipantsData() || undefined,
          Allocation: this.participantsSelectorAPI.getAllocation() || undefined
        }
      : {}
  }

  _getParticipantsSelectorAPI () {
    if (!this.participantsSelectorAPI) {
      const participantsSelector = this.element.querySelector('[data-js-component="c-participants-selector"]')
      this.participantsSelectorAPI = participantsSelector && participantsSelector['c-participants-selector']
    }
    return this.participantsSelectorAPI
  }
}

registerWidget(QuickSearch, widgetApi)
