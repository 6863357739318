/**
 * Arrayifies recursively an object to weird Sundio way:
 *
 * {key: ['v1', 'v2']}  =>  {key[0]: 'v1', key[1]: 'v2'}
 *
 * @param {Object} object
 *
 * @returns {Object}
 */

export function arrayifyObject (object) {
  const arrayify = (key, value) => {
    return !Array.isArray(value)
      ? { [key]: value }
      : value.reduce((obj, item, index) => ({
        ...obj,
        ...arrayify(`${key}[${index}]`, item)
      }), {})
  }
  return Object.keys(object).reduce((obj, key) => ({
    ...obj,
    ...arrayify(key, object[key])
  }), {})
}

/**
 * Derrayifies recursively an object from weird Sundio way:
 *
 * {key[0]: 'v1', key[1]: 'v2'}  =>  {key: ['v1', 'v2']}
 *
 * @param {Object} object
 *
 * @returns {Object}
 */

export function derrayifyObject (object) {
  const newObject = {}
  Object.entries(object).forEach(([key, value]) => {
    const indexRegExp = /\[(\d+)\]/g
    try {
      const indexes = key.match(indexRegExp).map(i => parseInt(i.replace(indexRegExp, '$1')))
      const originalKey = key.replace(indexRegExp, '')
      newObject[originalKey] = newObject[originalKey] || []
      const setValueAtIndexes = (arr, indexes, val) => {
        if (indexes.length < 2) {
          arr[indexes[0]] = val
        } else {
          arr[indexes[0]] = arr[indexes[0]] || []
          setValueAtIndexes(arr[indexes[0]], indexes.slice(1), val)
        }
      }
      setValueAtIndexes(newObject[originalKey], indexes, value)
    } catch (e) {
      newObject[key] = object[key]
    }
  })
  return newObject
}
