import * as namespace from './namespace'
import { EXCLUDE_WHITELISTED_PARAMS } from '../helpers/white-listed-params'
import { removeUndefinedKeys } from '../helpers/object'

let helper = namespace.register('sundio.helpers.url')

/**
 * getAbsoluteUrl
 * Returns the absolute url from a relative path or another url
 *
 * @param {String} url
 * @returns {String} absolute url
 */
export function getAbsoluteUrl (url) {
  if (url.startsWith('http') || url.startsWith('//')) return url
  if (url.startsWith('/')) return window.location.origin + url
  return window.location.origin + relPathToAbs(url)
}

helper.getAbsoluteUrl = getAbsoluteUrl

/**
 * relPathToAbs
 * convert a relative path to absolute path
 *
 * @param {String} sRelPath
 * @returns {String} absolute path
 */
export function relPathToAbs (sRelPath) {
  var nUpLn, sDir = '', sPath = window.location.pathname.replace(/[^\/]*$/, sRelPath.replace(/(\/|^)(?:\.?\/+)+/g, '$1'))
  for (var nEnd, nStart = 0; nEnd = sPath.indexOf('/../', nStart), nEnd > -1; nStart = nEnd + nUpLn) {
    nUpLn = /^\/(?:\.\.\/)*/.exec(sPath.slice(nEnd))[0].length
    sDir = (sDir + sPath.substring(nStart, nEnd)).replace(new RegExp('(?:\\\/+[^\\\/]*){0,' + ((nUpLn - 1) / 3) + '}$'), '/')
  }
  return sDir + sPath.substr(nStart)
}

helper.relPathToAbs = relPathToAbs

/**
 * Gets current search params from current URL or given one
 * - It will also decode URL, so you don't need to do it before.
 * - ⚠️ WARNING: Decoding URL searchParam values could cause problems,
 *   ensure those doesn't contain any special char that requires codification
 *
 * @param {String} [url]
 * @param {Object} [options]
 * @param {Boolean} options.useUndefinedValue
 *
 * @returns {Object}
 */
export function getSearchParamsFromUrl (url = document.location, options = {}) {
  const targetUrl = url instanceof (window.URL)
    ? url
    : new window.URL(decodeURIComponent(url))
  return targetUrl.search
    ? [...targetUrl.searchParams.entries()].reduce((obj, [key, val]) => ({...obj, [key]: val === '' && options.useUndefinedValue ? undefined : val}), {})
    : undefined
}

/**
 * Builds the request URL, appending the query params if there's any
 *
 * @param {String} urlString
 * @param {Object} [queryParams]
 *
 * @returns {URL}
 */
export function getUrlFromString (urlString, queryParams) {
  const requestUrl = new window.URL(getAbsoluteUrl(urlString))
  if (queryParams) {
    Object.entries(queryParams).forEach(([name, value]) => {
      if (value !== undefined) {
        requestUrl.searchParams.append(name, value)
      }
    })
  }
  return requestUrl
}

/**
 * Builds the request URL, appending the query params if there's any
 *
 * @param {String} urlString
 * @param {Object} [queryParams]
 * @param {Object} [options]
 * @property {Boolean} [options.absolute] - Ensure absolute URL
 * @property {String} [options.hash] - hash value to anchor
 *
 * @returns {URL}
 */
export function buildUrlWithParams (urlString, queryParams = {}, options = {}) {
  const newUrl = new window.URL(
    options.absolute
      ? getAbsoluteUrl(urlString)
      : urlString
  )
  Object.entries(queryParams).forEach(([name, value]) => {
    newUrl.searchParams.append(name, value)
  })

  const currentHash = window.location.hash
  if (options.hash !== undefined) {
    newUrl.hash = options.hash
  } else if (currentHash !== null && currentHash !=="") {
    if (newUrl.hash === null || newUrl.hash === "") {
      newUrl.hash = currentHash
    }
  }

  return newUrl
}

/**
   * Returns the params from URL params, if there's any
   * - Get the relevant params from the URL
   *
   * @returns {Object}
   */
 export function getParamsFromURLForBookingSteps () {
  if (!document.location.search) return {}
  const paramsFromUrl = getSearchParamsFromUrl(document.location, { useUndefinedValue: true })
  let relevantUrlParams = EXCLUDE_WHITELISTED_PARAMS(
    removeUndefinedKeys(paramsFromUrl)
  )

  if (!relevantUrlParams || Object.keys(relevantUrlParams).length === 0) return {}

  if (paramsFromUrl.debug) {
    relevantUrlParams = { ...relevantUrlParams, ...{ debug: paramsFromUrl.debug } }
  }

  return relevantUrlParams
}
