import { getUrlFromString } from '../../../js/document/url'
import { BtnTemplate, defaultButtonData } from '../../components/btn/c-btn.template'

/**
 * The PriceData contains all data needed to hydrate a Price view
 *
 * @typedef {Object}          PriceData
 *
 * @property {String}         value                - The price number itself
 * @property {String}         currency             - The currency symbol
 * @property {String}         currencyPosition     - The currency position (before, after)
 * @property {String}        [priceSuffix]         - Price suffix text
 * @property {String}        [pricePrefix]         - Price prefix text
 * @property {String}        [size]                - The optional price size modifier (small, large)
 * @property {String}        [saleText]            - Discount or sale text to be highlighted
 * @property {String}        [oldPrice]            - Previous public price
 * @property {String}        [legend]              - Price legend as text, like: 'price per person'
 * @property {String}        [acmUrl]              - ACM url where to request info to be displayed
 * @property {String}        [acmModalId]          - Modal ID where the ACM info will be shown at
 * @property {Boolean}       [acmHideInfoIcon]     - Hide the ACM info icon (an 'i' in an circle).
 * @property {String}        [publicPrice]         - Extra string to append below the price, like public price if you book elsewhere
 * @property {String}        [publicPriceDetail]   - Extra string for the public price detail text
 * @property {String}        [extraClasses]        - Extra classes to be appended
 * @property {Boolean}       [hasUiTest]           - Flag to indicate if this component is subjected to a UI test.
 */
export const defaultPriceData = {
  value: '',
  currency: '€',
  currencyPosition: 'after',
  priceSuffix: '',
  pricePrefix: '',
  size: '',
  saleText: '',
  oldPrice: '',
  legend: '',
  acmUrl: '',
  acmModalId: '',
  acmHideInfoIcon: false,
  publicPrice: '',
  publicPriceDetail: '',
  extraClasses: '',
  hasUiTest: false
}
export const defaultDemoPriceData = {
  value: '1.245',
  currency: '€',
  currencyPosition: 'before',
  priceSuffix: 'p.p.',
  pricePrefix: '',
  size: 'medium',
  saleText: '-15%',
  oldPrice: '1.900',
  label: 'This is the label',
  labelGrayed: true,
  legend: 'Price per person',
  acmUrl: '',
  acmModalId: '',
  acmHideInfoIcon: false,
  publicPrice: 'Public price 299€',
  publicPriceDetail: 'pulic ski pass 99€',
  extraClasses: '',
  hasUiTest: false
}

export const PriceTemplate = (d) => {
  d = { ...defaultPriceData, ...d }

  return `
  <div class="c-price ${d.size ? `c-price--${d.size}` : ''} ${d.extraClasses ? d.extraClasses : ''}">
      ${d.discount ? discountTemplate(d) : ''}
      ${d.saleText || d.oldPrice
        ? `<div class="c-price__sale-info">
          ${d.saleText ? `<span class="c-label c-price__sale-text c-label--sale">${d.saleText}</span>` : ''}
          ${d.oldPrice && d.publicPrice === undefined ? `<span class="c-price__old-price">${d.currencyPosition === 'before' ? d.currency : ''}${d.oldPrice}${d.currencyPosition === 'after' ? d.currency : ''}</span>` : ''}
          ${d.publicPrice ? `<div class="c-price__public-price m-body m-body--tiny">${d.publicPrice}</div>` : ''}
        </div>`
        : ''}
      ${d.acmUrl || d.legend
        ? `<div class="c-price__info m-body m-body--small">
          ${d.legend ? `<span class="c-price__legend">${d.legend}</span>` : ''}
          ${d.acmUrl && !d.acmHideInfoIcon
            ? `<button type="button" class="c-btn c-btn--icon-only c-price__info-icon" data-js-component="c-btn" data-c-modal__action="open" data-c-modal__source="${d.acmUrl}" data-c-modal__id="${d.acmModalId}"><i class="c-btn__icon c-btn__icon--left m-icon--information-circle"></i></button>`
            : ''}
          ${d.icon
            ? `${BtnTemplate({
              ...defaultButtonData,
              ...{
                text: '',
                variant: 'icon-only',
                block: false,
                icon: d.icon,
                link: false,
                extraClasses: 'c-price__info-icon'
              }
            })}`
            : ''}
        </div>`
      : ''}
      <div class="c-price__number m-price ${d.size ? `m-price--${d.size}` : ''}">
        ${d.oldPrice ? `<span class="c-price__old-price discount-ab-test">${d.currencyPosition === 'before' ? d.currency : ''}${d.oldPrice}${d.currencyPosition === 'after' ? d.currency : ''}</span>` : ''}
        ${d.pricePrefix ? `<span class="c-price__prefix m-price__prefix">${d.pricePrefix}</span>` : ''}
        ${d.currency && d.currencyPosition === 'before' ? `<span class="c-price__currency m-price__currency">${d.currency}</span>` : ''}` +
        `<span class="c-price__value m-price__value ${d.hasUiTest ? 'qa-price-value' : ''}">${d.value}</span>` +
        `${d.currency && d.currencyPosition === 'after' ? `<span class="c-price__currency m-price__currency">${d.currency}</span>` : ''}` +
        `${d.priceSuffix ? `<span class="c-price__suffix m-price__suffix">${d.priceSuffix}</span>` : ''}
      </div>
      ${d.label
        ? `<div class="c-price__info m-body m-body--small">
          <span class="c-price__label ${d.labelGrayed ? 'c-price__label-grayed' : ''}">${d.label}</span>
        </div>`
        : ''}
      ${d.publicPriceDetail
        ? publicPriceDetailTemplate(d)
        : ''}
  </div>`
}

/**
 * Transform formatted price into {value, currency, currencyPosition}
 *
 * @param {String} formattedPrice - ("201 kr", "€ 500")
 * @param {String} [symbol] - Support for +, - or any desirable one
 *
 * @returns {PriceData}
 */
export const parseFormattedPrice = (formattedPrice, symbol = '') => {
  const currency = (formattedPrice.match(/([^., \d])+/g) || [])[0]
  const { value, currencyPosition } = formattedPrice.split(currency).map((numberString, index) => ({
    value: numberString.trim(),
    currencyPosition: (index ? 'before' : 'after')
  })
  ).filter(({ value }) => !isNaN(parseFloat(value)))[0]
  return { value: symbol + value, currency, currencyPosition }
}

/**
 * ACM params keys are different from acmInformation keys returned by price object from APIs.
 * This maps ones to others, where the key is from acmInformation and value ACM api param keys.
 */
const ACM_PARAMS_MAP = {
  transportType: 'transporttype',
  departureDateRaw: 'departuredate',
  durationRaw: 'duration',
  birthdates: 'participants',
  mealplanCode: 'mealplan',
  allocation: 'allocation'
}

export const createAcmUrlFromParams = (baseUrl, params) => {
  const fixedParams = Object.keys(params).reduce((result, paramKey) => {
    result[ACM_PARAMS_MAP[paramKey] || paramKey] = params[paramKey]
    return result
  }, {})
  return getUrlFromString(baseUrl, fixedParams)
}

function discountTemplate (d) {
  return `
    <div class="c-price__discount"> 
      <span class="m-icon--wallet c-price__discount-icon"></span>
      <span class="c-price__discount-text">${d.discountText}</span>
      <span class="c-price__discount-value">${d.currencyPosition === 'before' ? d.currency : ''}${d.discount}${d.currencyPosition === 'after' ? d.currency : ''}</span>
    </div>`
}

export function getACMPriceLabel (d) {
  return BtnTemplate({
    text: `${d.text.replace('<a>', '<a class="m-link c-price__extra-info-link">')} ${d.staticText || ''}`,
    attributes: { 'data-c-modal__action': 'open', 'data-c-modal__source': d.acmUrl, 'data-c-modal__id': d.modalId },
    variant: 'icon-only',
    extraClasses: 'c-price__info-icon c-price__extra-info-label'
  })
}

export function publicPriceDetailTemplate ({ publicPriceDetail }) {
  return `<div class="c-price__public-price-detail m-body m-body--tiny">
    <span class="m-icon m-icon--snowflake"></span>
    <span class="c-price__public-price-detail-text">${publicPriceDetail}</span>
  </div>`
}
