export const TRANSPORT_TYPE_VALUES = { FLIGHT: 'Flight', BUS: 'Bus', SELF_DRIVE: 'SelfDrive' }

export const FILTER_TYPES = {
  DESTINATION: 'Destination',
  PARTICIPANTS: 'Participants',
  PARTICIPANTS_DISTRIBUTION: 'ParticipantsDistribution',
  TRANSPORT_TYPE: 'TransportType',
  DURATION: 'Duration',
  DURATIONS: 'Durations',
  DURATION_GROUP: 'DurationGroup',
  DEPARTURE_AIRPORT: 'DepartureAirport',
  ARRIVAL_AIRPORT: 'ArrivalAirport',
  MEALPLAN: 'Mealplan',
  MONTH: 'Month',
  DEPARTURE_DATE: 'DepartureDate',
  ALLOCATION: 'Allocation',
  ROOM_TYPE: 'RoomType',
  STARS: 'Stars',
  COUNTRY: 'Country',
  CITY: 'City',
  SUBREGION: 'Subregion',
  REGION: 'Region',
  LAST_MINUTE: 'Lastminute',
  PRICE_PER_PERSON: 'PricePerPerson',
  ROOM_COUNT: 'RoomCount',
  SKI_AREA_MINIMUM_ALTITUDE_METERS: 'SkiAreaMinimumAltitudeMeters',
  BATHROOM_COUNT: 'BathroomCount',
  FACILITIES: 'Facilities',
  INTERNET_FACILITIES: 'InternetFacilities',
  CHILDREN_FACILITIES: 'ChildrenFacilities',
  IS_WIFI_FREE: 'IsWifiFree',
  RECOMMENDED: 'Recommended',
  CAMPAIGNS: 'Campaigns',
  THEMES: 'Themes',
  ACCOMMODATION_TYPE: 'AccommodationType',
  CUSTOMERS_RATING: 'CustomersRatings',
  METERS_TO_CENTER: 'MetersToCenter',
  METERS_TO_SLOPE: 'MetersToSlope',
  METERS_TO_SKILIFT: 'MetersToSkiLift',
  METERS_TO_BEACH: 'MetersToBeach',
  DISCOUNT: 'Discount',
  BEDS_IN_LIVING_ROOM: 'BedsInLivingroom',
  SKI_PASS_INCLUDED: 'SkiPassIncluded',
  SKI_AREA_SLOPE_KMS: 'SkiAreaSlopeKms',
  HAS_AVAILABILITY: 'HasAvailability',
  FLIGHT_ONLY: 'FlightOnly',
  COLLECTION: 'Collection',
  AIRLINE: 'Airline'
}
export const DESTINATION_FILTER_TYPES = [
  FILTER_TYPES.COUNTRY,
  FILTER_TYPES.CITY,
  FILTER_TYPES.SUBREGION,
  FILTER_TYPES.REGION,
  FILTER_TYPES.DESTINATION
]
